@use "./utils" as *;
@use "./global" as *;

.section4 {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: rgb(3, 7, 18);
}
.flex_row {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 0px 10px;
  margin: 96px auto 101px;
  width: 1215.921875px;
  max-width: 85%;
  @include lg {
    width: 85%;
  }
  @include md {
    flex-direction: column;
    align-items: center;
    gap: 30px 10px;
  }
  @include xs {
    margin: 80px auto;
  }
  @include tn {
    margin: 50px auto;
  }
}
.flex_row1 {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 0px 10px;
  margin: 120px 0px 8px;
  width: 320px;
  min-width: 0px;
  @include lg {
    margin: 145px 0px 8px;
  }
  @include md {
    flex: 0 1 auto;
    order: 3;
    margin-top: unset;
    margin-bottom: unset;
    width: 85%;
    min-width: unset;
  }
  @include xs {
    margin: 0px 0px 66px;
  }
  @include tn {
    margin: 0px 0px auto;
  }
}
.flex_col {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 88px;
  min-width: 0px;
}
.image9 {
  position: relative;
  margin: 0px 12px;
  width: 64px;
  height: 64px;
  object-fit: cover;
  @include tn {
    margin: 0px 8px;
  }
}
.highlight3 {
  position: relative;
  margin: 8px 0px 0px;
  font: 400 18px/1.55 "Inter", Helvetica, Arial, serif;
  color: rgb(209, 213, 219);
  @include md {
    align-items: flex-start;
    font-size: 16px;
  }
}
.image18 {
  position: relative;
  margin: 51px 17px 0px 9px;
  width: 62px;
  height: 80px;
  object-fit: cover;
  @include xs {
    margin: 51px 16px 0px 9px;
  }
  @include tn {
    margin: 50px 8px 0px;
  }
}
.highlight31 {
  position: relative;
  margin: 8px 28px 0px 20px;
  font: 400 18px/1.55 "Inter", Helvetica, Arial, serif;
  color: rgb(209, 213, 219);
  @include md {
    align-items: flex-start;
    font-size: 16px;
  }
  @include xs {
    margin: 8px 16px 0px;
  }
  @include tn {
    margin: 8px 8px 0px;
  }
}
.flex_col1 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 0px 3px;
  width: 95px;
  min-width: 0px;
}
.image11 {
  position: relative;
  width: 70px;
  max-width: 85%;
  height: 64px;
  object-fit: cover;
}
.image19 {
  position: relative;
  margin: 66px 0px 0px;
  width: 63px;
  height: 64px;
  object-fit: cover;
  @include tn {
    margin: 50px 0px 0px;
  }
}
.image20 {
  position: relative;
  margin: 8px 0px 0px;
  width: 100%;
  height: 26px;
  object-fit: cover;
}
.flex_col2 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px 0px;
  margin: 0px 0px 11px;
  width: 499px;
  min-width: 0px;
  @include md {
    flex: 0 1 auto;
    order: 1;
    margin-top: unset;
    margin-bottom: unset;
    width: 100%;
    min-width: unset;
  }
}
.flex_col3 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px 0px;
  width: 100%;
}
.btn {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0px auto;
  width: 75px;
  max-width: 100%;
  font: 500 14px/1.42 "Inter", Helvetica, Arial, serif;
  color: rgb(209, 213, 219);
  text-align: center;
  background-color: rgb(55, 65, 81);
  border-radius: 12px 12px 12px 12px;
  padding: 4px 20px 4px 20px;
}
.subtitle {
  position: relative;
  display: flex;
  justify-content: center;
  font: 400 20px/1.4 "Inter", Helvetica, Arial, serif;
  color: rgb(209, 213, 219);
  text-align: center;
  @include md {
    align-items: flex-start;
    font-size: 18px;
  }
  @include xxs {
    font-size: 16px;
  }
}
.flex_row2 {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 0px 10px;
  width: 318px;
  max-width: 85%;
  @include lg {
    width: 70%;
  }
  @include md {
    width: 85%;
  }
  @include xs {
    margin: 0px 0px 66px;
  }
  @include tn {
    column-gap: 8px;
    margin: auto 0px;
  }
}
.flex_col4 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 105px;
  min-width: 0px;
}
.image14 {
  position: relative;
  width: 100%;
  height: 64px;
  object-fit: cover;
}
.image21 {
  position: relative;
  margin: 66px 0px 0px;
  width: 63px;
  height: 66px;
  object-fit: cover;
  @include tn {
    margin: 50px 0px 0px;
  }
}
.image22 {
  position: relative;
  margin: 8px 0px 0px;
  width: 40px;
  height: 24px;
  object-fit: cover;
}
.flex_col5 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 79px;
  min-width: 0px;
}
.image15 {
  position: relative;
  margin: 0px 5px 0px 18px;
  width: 56px;
  height: 64px;
  object-fit: cover;
  @include xs {
    margin: 0px 5px 0px 16px;
  }
  @include tn {
    margin: 0px 5px 0px 8px;
  }
}
.highlight32 {
  position: relative;
  margin: 8px 0px 0px 14px;
  font: 400 18px/1.55 "Inter", Helvetica, Arial, serif;
  color: rgb(209, 213, 219);
  @include md {
    align-items: flex-start;
    font-size: 16px;
  }
  @include tn {
    margin: 8px 0px 0px 8px;
  }
}
.image23 {
  position: relative;
  margin: 66px 10px 0px 6px;
  width: 63px;
  height: 69px;
  object-fit: cover;
  @include tn {
    margin: 50px 8px 0px 6px;
  }
}
.image24 {
  position: relative;
  margin: 8px 4px 0px 0px;
  width: 94.94%;
  height: 21px;
  object-fit: cover;
}
.flex_row3 {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0px 10px;
  margin: 120px 0px 0px;
  width: 298px;
  min-width: 0px;
  @include lg {
    margin: 140px 0px 0px;
  }
  @include md {
    flex: 0 1 auto;
    order: 5;
    margin-top: unset;
    margin-bottom: unset;
    width: 85%;
    min-width: unset;
  }
  @include tn {
    column-gap: 8px;
    margin: auto 0px;
  }
}
.flex_col6 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 86px;
  min-width: 0px;
}
.image91 {
  position: relative;
  margin: 0px 11px;
  width: 64px;
  height: 64px;
  object-fit: cover;
  @include tn {
    margin: 0px 8px;
  }
}
.image25 {
  position: relative;
  margin: 49px 12px 0px 1px;
  width: 73px;
  max-width: 85%;
  height: 90px;
  object-fit: cover;
  @include tn {
    margin: 49px 8px 0px 1px;
  }
}
.highlight33 {
  position: relative;
  margin: 8px 17px 0px 6px;
  font: 400 18px/1.55 "Inter", Helvetica, Arial, serif;
  color: rgb(209, 213, 219);
  @include md {
    align-items: flex-start;
    font-size: 16px;
  }
  @include xs {
    margin: 8px 16px 0px 6px;
  }
  @include tn {
    margin: 8px 8px 0px 6px;
  }
}
.flex_col7 {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 64px;
}
.image92 {
  position: relative;
  width: 64px;
  height: 64px;
  object-fit: cover;
}
.highlight34 {
  position: relative;
  margin: 8px 19px 0px 20px;
  font: 400 18px/1.55 "Inter", Helvetica, Arial, serif;
  color: rgb(209, 213, 219);
  @include md {
    align-items: flex-start;
    font-size: 16px;
  }
  @include xs {
    margin: 8px 16px 0px;
  }
  @include tn {
    margin: 8px 8px 0px;
  }
}
.image26 {
  position: relative;
  margin: 65px 15px 0px 7px;
  width: 42px;
  height: 64px;
  object-fit: cover;
  @include tn {
    margin: 50px 8px 0px 7px;
  }
}
.highlight35 {
  position: relative;
  margin: 8px 10px 0px 2px;
  font: 400 18px/1.55 "Inter", Helvetica, Arial, serif;
  color: rgb(209, 213, 219);
  @include md {
    align-items: flex-start;
    font-size: 16px;
  }
  @include tn {
    margin: 8px 8px 0px 2px;
  }
}
